
<section class="change-pass-section">
    <div class="change-pass-container">
        <h1 class="mb-2 text-center contact-us-heading">Contact Us</h1>

        <form [formGroup]="dataForm" (ngSubmit)="submitForm()" #f="ngForm">
            <div class="row">
                <div class="col-md-4 mb-2 cg-input-group pb-1">
                    <label class="mb-0 mt-0 label">Name<span class="mendatory">*</span></label>
                    <input type="text" class="cg" formControlName="name" placeholder="Enter Nmae"
                        [ngClass]="{'form-submitted': f.submitted}">
                    <div *ngIf="ui.checkErrors(g.name, f)" class="errors">
                        <p *ngIf="g.name.errors?.required">name is required</p>
                    </div>
                </div>
                <div class="col-md-4 mb-2 cg-input-group pb-1">
                    <label class="mb-0 mt-0 label">Contact Number<span class="mendatory">*</span></label>
                    <input type="text" class="cg fadeIn" formControlName="phoneNumber" placeholder="Enter Contact Number"
                        [ngClass]="{'form-submitted': f.submitted}">
                    <div *ngIf="ui.checkErrors(g.phoneNumber, f)" class="errors">
                        <p *ngIf="g.phoneNumber.errors?.required">contact number is required</p>
                    </div>
                </div>
                <div class="col-md-4 mb-2 cg-input-group pb-1">
                    <label class="mb-0 mt-0 label">Email <span class="mendatory">*</span></label>
                    <input type="email" class="cg fadeIn" formControlName="email" placeholder="Enter email"
                        [ngClass]="{'form-submitted': f.submitted}">
                    <div *ngIf="ui.checkErrors(g.email, f)" class="errors">
                        <p *ngIf="g.email.errors?.email">enter valid email</p>
                        <p *ngIf="g.email.errors?.required">email is required</p>
                        <p *ngIf="g.email.errors?.maxlength">maximum {{ g.email.errors?.maxlength.requiredLength }} cahracters are allowed.
                    </div>
                </div>
                <div class="col-md-12 mt-2 mb-4 cg-input-group pb-1">
                    <label class="mb-0 mt-0 label">Message <span class="mendatory">*</span></label>
                    <textarea formControlName="message" class="cg fadeIn" rows="4"
                        placeholder="Enter message" [ngClass]="{'form-submitted': f.submitted}"></textarea>

                    <div *ngIf="ui.checkErrors(g.message, f)" class="errors">
                        <p *ngIf="g.message.errors?.required">Message is required</p>
                    </div>

                </div>
                <div class="col-md-12 text-center">
                    <button type="submit" class="cg lg primary">
                        Send Message
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</section>
