import { HttpClient } from '@angular/common/http'
import { apis } from './../../../environments/environment'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class DataService {
    private baseUrl = `${apis.baseUrl}`
constructor(private http: HttpClient) { }

contactUsDetails(data: any) {
    const url = `${this.baseUrl}/public/contact-us/save`
    return this.http.post(url, data)
}

}
