import { DataService } from './data.service'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from './../shared/shared.module'
import { Routes, RouterModule } from '@angular/router'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ContactUsComponent } from './contact-us.component'

const routes: Routes = [
    { path: '', component: ContactUsComponent }
]

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes)
    ],
    declarations: [ContactUsComponent],
    providers: [DataService]
})
export class ContactUsModule { }
