import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { ConstantsService } from './../../services/constants.service'
import { DataService } from './data.service'
import { IAlertService } from './../../libs/ialert/ialerts.service'
import { UIHelpers } from './../../helpers/ui-helpers'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from './../../services/api.service'
import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
    dataForm: FormGroup
    lang: any = ''
    contents: any = ''
    loadingStatus = false
    searching = false
    constructor(
        public api: ApiService,
        public ds: DataService,
        public fb: FormBuilder,
        private alert: IAlertService,
        public ui: UIHelpers,
        private router: Router,
        public cs: ConstantsService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer
    ) {
        this.dataForm = new FormGroup({
            name: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
            phoneNumber: new FormControl(null, [Validators.required, Validators.maxLength(15)]),
            email: new FormControl(null, [
                Validators.required,
                Validators.maxLength(25),
                Validators.email
            ]),
            message: new FormControl(null, [Validators.required])
        })
    }

    ngOnInit() {
        this.loadingStatus = true
        this.lang = this.api.translate('website.contact-us')
        this.lang.subscribe((d: any) => {
            this.lang = d
        })
    }

    get g() {
        return this.dataForm.controls
    }

    submitForm() {
        const data = this.dataForm.getRawValue()
        if (this.dataForm.invalid) {
            this.alert.error(this.lang.errorIncomplete, 'Invalid Data')
            return
        }
        this.ds.contactUsDetails(data).subscribe((resp: any) => {
            if (resp.success === true) {
                this.alert.success(resp.message)
                this.router.navigate(['/'])
            } else {
                this.alert.error(resp.message)
            }
        })
    }

    transformHtml(htmlTextWithStyle: any): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle)
    }

}
